import React from 'react';
import ReactMarkdown from 'react-markdown';
import tw from 'twin.macro';
import { codeComponent } from '../utils/markdownComponents';

const useReactMarkdownVideoRenderer = (str) => {
  const mds = [];

  var startIndices = [];
  for (var startIndex = 0; startIndex < str.length; startIndex++) {
    if (`${str[startIndex] + str[startIndex + 1]}` === '{{')
      startIndices.push(startIndex);
  }

  var stopIndices = [];
  for (var stopIndex = 0; stopIndex < str.length; stopIndex++) {
    if (`${str[stopIndex] + str[stopIndex + 1]}` === '}}')
      stopIndices.push(stopIndex + 1);
  }

  mds.push(
    <ReactMarkdown className="markdown-body" components={codeComponent}>
      {str.substring(0, startIndices[0])}
    </ReactMarkdown>
  );

  for (let index = 0; index < startIndices.length; index++) {
    const content = str
      .substring(startIndices[index], stopIndices[index])
      .replace(/{/g, '')
      .replace(/}/g, '');
    mds.push(
      <div css={[tw`flex justify-center my-12`]}>
        <iframe
          css={['width: 712px;height: 400px;']}
          src={content}
          title={content}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    );
    mds.push(
      <ReactMarkdown className="markdown-body" components={codeComponent}>
        {str.substring(stopIndices[index] + 1, startIndices[index + 1])}
      </ReactMarkdown>
    );
  }

  return mds.filter((x) => x !== '');
};

export default useReactMarkdownVideoRenderer;
